let Environment = {
  factoryContarctVariable: "0x41e7cfe58Cb6a36EcF3AAD8f9dF8FB8319d46690",
  factoryContarct: "0x0aa795a84a52AC22d4F8004b344f2b46B765E69B",

  StakeBNBtoBNB: "0x82687d315B488b347177924b0cD5C0c3188Bc350",
  StakeBNBtoToken: "0x68744fAbc21888908a9fe7CacCE777CDc9ba76b4",
  StaktTokentoBNB: "0x288C82A97ac12e601e59f45A7CC45746b6366045",
  StakeTokentoToken: "0xfDf47320dC8bA542C2Bb90799649b82B07B59B59",

  VStakeBNBtoBNB: "0x7dc4E2f03aC9f91aB3e7b9b09D58b40aaf4d634a",
  VStakeBNBtoToken: "0xb9Eb69633366aDD56689FBE0edc378907D89E6A7",
  VStaktTokentoBNB: "0xf49eDd5E3b9D4Bbb50b16D787f89eEb5977B2437",
  VStakeTokentoToken: "0x9Bd28aB5c3a57c68F7e42F0Af4100F659eA1A82A",
};
export default Environment;


// let Environment = {
//   factoryContarctVariable: "0x41e7cfe58Cb6a36EcF3AAD8f9dF8FB8319d46690",
//   factoryContarct: "0x0aa795a84a52AC22d4F8004b344f2b46B765E69B",

//   StakeBNBtoBNB: "0x82687d315B488b347177924b0cD5C0c3188Bc350",
//   StakeBNBtoToken: "0x68744fAbc21888908a9fe7CacCE777CDc9ba76b4",
//   StaktTokentoBNB: "0x288C82A97ac12e601e59f45A7CC45746b6366045",
//   StakeTokentoToken: "0xfDf47320dC8bA542C2Bb90799649b82B07B59B59",

//   VStakeBNBtoBNB: "0x7dc4E2f03aC9f91aB3e7b9b09D58b40aaf4d634a",
//   VStakeBNBtoToken: "0xb9Eb69633366aDD56689FBE0edc378907D89E6A7",
//   VStaktTokentoBNB: "0xf49eDd5E3b9D4Bbb50b16D787f89eEb5977B2437",
//   VStakeTokentoToken: "0x9Bd28aB5c3a57c68F7e42F0Af4100F659eA1A82A",
// };
// export default Environment;